@import "../common";

.admin-travel-reports {
    &-date-col {
        width: 200px;
    }

    &-row {
        cursor: pointer;
    }
}