@import "../common";


.month-select {
    &-button {
        width: 100%
    }

    &-year {
        @extend .text-center;
        flex-grow: 1;
        user-select: none;

        &-button {
            @extend .p-0;
            user-select: none;

            & > svg:hover {
                @extend .border;
                @extend .rounded;
                cursor: pointer;
            }
        }
    }

    &-month {
        &-button {
            @extend .p-0;
            margin: 2px !important;

            & > * {
                @extend .btn-sm;
                @extend .btn-secondary;
                width: 55px;
            }

            &-selected > * {
                @extend .btn-primary;
            }
           
        }
    }
}