@import "../common";


.travel-table {
    &-date-col {
        width: 200px;
    }

    &-row-weekend { 
        background-color: $table-striped-bg
    }
}